import { useStaticQuery, graphql } from "gatsby"

export const useThanksAssets = () => {
  const images = useStaticQuery(
    graphql`
      query ThanksImages {
        cover: file(relativePath: { eq: "flipunit__thanks__cover.jpg" }) {
          src: childImageSharp {
            fluid {
              src
            }
          }
        }
        mediaVideo: file(relativePath: { eq: "avc_26_sh_21_9.mp4" }) {
          publicURL
        }
      }
    `
  )

  return {
    cover: images.cover.src.fluid.src,
    video: images.mediaVideo.publicURL,
  }
}
